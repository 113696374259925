function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
  }
  
  // example use
  var contentFeed = document.querySelector('.c-content-feed');
  var contentFeedOffset = offset(contentFeed);
  // console.log(contentFeedOffset.top);
  
  if(contentFeedOffset.top === 0) {
    document.body.classList.add("is-feed-top");
    console.log('added');
  } else {
    document.body.classList.remove("is-feed-top");
    console.log('removed');
  }
  